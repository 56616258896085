import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import fadeIn from "./fadeIn";
import customScrollbar from "./customScrollbar";
import menu from "./menu";
import introAnimation from "./introAnimation";

const initAlpine = () => {
	Alpine.plugin(collapse);
	window.Alpine = Alpine;
	Alpine.start();
};

const initAlpineComponents = () => {
	introAnimation();
	menu();
	fadeIn();
	customScrollbar();
};

if (!window.Alpine) {
	document.addEventListener("alpine:init", initAlpineComponents);
	initAlpine();
}
