import "vite/modulepreload-polyfill";
import "../styles/app.css";
import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://54d83ecbfaf12cf9081849d787fa7e9a@o4504918812983296.ingest.us.sentry.io/4506822260883456",
	});
}

import "./includes/htmx";
import "./includes/alpine";
