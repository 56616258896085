export default () => {
	Alpine.directive("fade-in-up", (el, { value }, { effect }) => {
		el.classList.add("fade-in-up");
		el.style.transition = "all 0.5s 0.1s ease-in-out";
		el.style.opacity = 0;
		el.style.transform = "translateY(20px)";
		new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					el.style.opacity = 1;
					el.style.transform = "translateY(0)";
				}
			});
		}).observe(el);
	});
};
