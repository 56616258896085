import htmx from "htmx.org";
import initSwiper from "./swiper";

window.htmx = htmx;
htmx.on("htmx:load", initSwiper);

htmx.on("htmx:afterSwap", function (event) {
	// Tracking BS. If we do a htmx boosted request, we need to track the page view
	let { detail, target } = event;
	if (detail.boosted && target.nodeName === "BODY") {
		// Umami
		if (typeof window.umami !== "undefined") {
			window.umami.track();
		}
		// Google Analytics
		if (typeof window.gtag !== "undefined") {
			window.gtag("event", "page_view", {});
		}
		// Facebook Pixel
		if (typeof window.fbq !== "undefined") {
			window.fbq("track", "PageView");
		}
	}
});
