import Swiper from "swiper";
import "swiper/css/bundle";
import { Navigation } from "swiper/modules";

export default function initSwiper() {
	const swiper = document.querySelector(".swiper");
	if (!swiper) return;

	const next = swiper.querySelector(".swiper-next");
	const prev = swiper.querySelector(".swiper-prev");

	const config = {
		modules: [Navigation],
		direction: "horizontal",
		spaceBetween: 36,
		slidesPerView: 1.1,
		watchOverflow: true,
		breakpoints: {
			768: {
				slidesPerView: 2.1,
			},
			1536: {
				slidesPerView: 3,
			},
		},
		navigation: {
			nextEl: next,
			prevEl: prev,
		},
	};

	new Swiper(swiper, config);
}
